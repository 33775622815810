<template>
  <div class="flex items-stretch gap-x-1.5">
    <VcDropdownMenu
      placement="top-start"
      width="100%"
      max-height="h-auto"
      class="relative w-full"
      :class="{ 'open-content': open }"
      :y-offset="-1"
      @toggle="toggle($event)"
    >
      <template #trigger>
        <div
          class="flex h-10 w-full flex-wrap content-between items-center gap-1 rounded-md border border-[color:var(--color-neutral-a3)] bg-white p-[10px] text-sm leading-[1.3] text-[color:var(--color-footer-top-text)] [&_svg]:stroke-[white] [&_svg]:stroke-2"
          :class="{ 'rounded-t-none': open }"
        >
          <div class="flex flex-1 items-center gap-2 font-bold">
            <span class="flex size-5 shrink-0 justify-center text-sm text-[color:var(--color-footer-top-text)]">{{
              currentCurrency?.symbol
            }}</span>
            <span>{{ currentCurrency?.code }}</span>
          </div>

          <VcIcon
            class="shrink-0 text-[--color-footer-top-text]"
            size="xs"
            :name="open ? 'chevron-up' : 'chevron-down'"
          />
        </div>
      </template>

      <template #content>
        <VcMenuItem
          v-for="item in supportedCurrencies"
          :key="item.code"
          :active="item.code === currentCurrency?.code"
          color="neutral"
          @click="select(item.code)"
        >
          <div class="flex items-center gap-2">
            <span
              class="flex size-5 shrink-0 items-center justify-center text-sm text-[color:var(--color-footer-top-text)]"
            >
              {{ item.symbol }}
            </span>
            <span>{{ item.code }}</span>
          </div>
        </VcMenuItem>
      </template>
    </VcDropdownMenu>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { updateContact } from "@/core/api/graphql/account/mutations/updateContact";
import { useCurrency } from "@/core/composables";
import { useUser } from "@/shared/account/composables/useUser";
import type { InputUpdateContactTypeExtension } from "@/core/api/graphql/types";

const { currentCurrency, supportedCurrencies, saveCurrencyCode } = useCurrency();
const { user } = useUser();

const open = ref(false);

function toggle(value: boolean) {
  open.value = value;
}

async function select(code: string) {
  if (user.value?.contact?.id) {
    const contact: InputUpdateContactTypeExtension = {
      defaultLanguage: user.value?.contact.defaultLanguage,
      currencyCode: supportedCurrencies.value.find((currency) => currency.code === code)?.code,
      firstName: user.value?.contact.firstName,
      lastName: user.value?.contact.lastName,
      id: user.value?.contact.id,
    };

    await updateContact(contact);
  }

  if (currentCurrency.value?.code !== code) {
    saveCurrencyCode(code);
  }
}
</script>

<style lang="scss">
.vc-dropdown-menu__list > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--color-neutral-a3) !important;
}
.vc-menu-item__inner:not(:disabled),
.vc-menu-item__inner--color--neutral:hover,
.vc-menu-item__inner--color--neutral:focus {
  color: var(--color-neutral-a2) !important;
}
.vc-dropdown-menu__list {
  box-shadow: 0px 1px 3px 0px rgba(166, 175, 195, 0.4) !important;
}
.vc-menu-item {
  &__inner {
    @apply p-[10px] grid gap-1;
    &--color--neutral {
      &.vc-menu-item__inner--active {
        @apply bg-[--color-neutral-a5];
      }

      &:hover,
      &:focus {
        @apply bg-[--color-neutral-a5] outline-none;
      }
    }
    &.vc-menu-item__inner--size--md {
      @apply px-[10px] py-[9px];
    }
  }
}
.open-content {
  .vc-dropdown-menu__list {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
footer [id^="popover-"],
footer [id*=" popover-"] {
  inset: auto 0px 0px auto !important;
  transform: translate3d(-0.5px, -39px, 0px) !important;
}
</style>
