import { Logger } from "@/core/utilities";

export const transformUrl = (
  url: string,
  width: number,
  height: number,
  dpr: number = 1,
  quality: number = 90,
  format: string = "webp",
) => {
  try {
    const itemUrl = new URL(url);
    let itemUrlPathName = itemUrl.pathname;
    let itemUrlHost = itemUrl.host;
    let itemUrlProtocol = `${itemUrl.protocol}//`;
    let cdnCgi = `/cdn-cgi/image/w=${width},h=${height},q=${quality},f=${format},dpr=${dpr}`;
    const cdnHost = "thin01mstroc282inte.dxcloud.episerver.net"; // temporary solution

    if (itemUrlHost !== cdnHost && itemUrlHost !== "localhost:5000") {
      itemUrlProtocol = "https://";
      itemUrlHost = cdnHost;
      itemUrlPathName = `/${url}`;
    } else if (itemUrlHost === "localhost:5000") {
      itemUrlProtocol = "https://";
      cdnCgi = "";
    }
    return `${itemUrlProtocol}${itemUrlHost}${cdnCgi}${itemUrlPathName}`;
  } catch (error) {
    Logger.error(`"Invalid URL: ${url}`, error);
    throw error;
  }
};

export const transformImageUrls = (
  images: Array<{ url: string; name: string; description: string | undefined }>,
  width: number,
  height: number,
  dpr: number = 1,
) => {
  if (!images || !Array.isArray(images) || images.length === 0) {
    return [];
  }

  try {
    return images.map((image) => {
      return {
        ...image,
        url: transformUrl(image.url, width, height, dpr),
      };
    });
  } catch (error) {
    Logger.error("Object cannot be parsed.", error);
    return images;
  }
};
