<template>
  <div :id="componentId" class="image-gallery">
    <div
      class="image-gallery__images-container"
      :class="{ 'min-w-full': images?.length <= 1, 'has-thumbnails': images?.length > 1 }"
    >
      <template v-if="images?.length">
        <Swiper
          class="image-gallery__images"
          :modules="swiperModules"
          :thumbs="{ swiper: thumbsSwiper }"
          direction="horizontal"
          :breakpoints="{
            [mdScreenWidth]: {
              direction: 'vertical',
            },
          }"
          @swiper="setImagesSwiper"
          @slide-change="setActiveIndex"
        >
          <SwiperSlide v-for="(image, i) in regularImages" :key="image.url || i">
            <VcImage
              class="image-gallery__img"
              :src="image.url"
              :srcset="`${regularImages4x[i].url} 4x, ${regularImages3x[i].url} 3x, ${regularImages2x[i].url} 2x, ${image.url} 1x`"
              :width="DEFAULT_IMAGE_SIZE"
              :height="DEFAULT_IMAGE_SIZE"
              :alt="image.name"
              :data-te-img="image.url"
              :lazy="i === 0 ? false : true"
              @click="showDialog()"
            />
            <div v-if="getCaptionByLanguageCode(image.description)" class="image-gallery__img-caption hidden">
              {{ getCaptionByLanguageCode(image.description) }}
            </div>
          </SwiperSlide>
        </Swiper>
        <button
          type="button"
          class="image-gallery__button"
          :aria-label="$t('shared.catalog.product_details.product_image_gallery.zoom_in')"
          :title="$t('shared.catalog.product_details.product_image_gallery.zoom_in')"
          @click="showDialog()"
          @keydown="handleShowDialog"
        >
          <VcIcon class="image-gallery__icon" name="zoom-in" size="xs" aria-hidden="true" />
        </button>
      </template>

      <!-- no-image -->
      <VcImage v-else class="image-gallery__img image-gallery__img--no-img" />
    </div>

    <div v-if="images?.length > 1" class="image-gallery__thumbs-container">
      <Swiper
        class="image-gallery__thumbs"
        :slides-per-view="THUMBS_PER_VIEW"
        :slides-per-group="THUMBS_PER_VIEW - 1"
        :slides-offset-after="0"
        :modules="swiperModules"
        :navigation="{
          prevEl: `#${componentId} [data-nav-prev]`,
          nextEl: `#${componentId} [data-nav-next]`,
        }"
        watch-slides-progress
        direction="horizontal"
        space-between="16"
        :breakpoints="{
          [mdScreenWidth]: {
            direction: 'vertical',
          },
        }"
        @swiper="setThumbsSwiper"
      >
        <SwiperSlide v-for="(image, i) in thumbImages" :key="image.url || i" class="image-gallery__thumb">
          <VcImage
            :class="[
              'image-gallery__thumb-img',
              {
                'image-gallery__thumb-img--active': activeIndex === i,
              },
            ]"
            :src="image.url"
            :srcset="`${thumbImages4x[i].url} 4x, ${thumbImages3x[i].url} 3x, ${thumbImages2x[i].url} 2x, ${image.url} 1x`"
            :lazy="i < THUMBS_PER_VIEW ? false : true"
            tabindex="0"
            @keydown="handleKeyDownThumb($event, i)"
          />
        </SwiperSlide>
      </Swiper>
      <div class="image-gallery__nav-buttons" :class="[{ 'opacity-0': !showSwiperNav }]" :inert="!showSwiperNav">
        <VcNavButton
          :label="$t('common.buttons.previous')"
          size="xs"
          :direction="isDesktop ? 'up' : 'left'"
          data-nav-prev
        />
        <div class="image-gallery__thumbs-counter text-base font-medium md:text-sm">
          {{ activeIndex + 1 }}/{{ thumbImages.length }}
        </div>
        <VcNavButton
          :label="$t('common.buttons.next')"
          size="xs"
          :direction="isDesktop ? 'down' : 'right'"
          data-nav-next
        />
      </div>
    </div>
  </div>

  <Dialog
    v-if="images?.length"
    ref="dialog"
    class="image-gallery-dialog"
    dialog-mode="large"
    content-class="px-4 pb-8"
    header-class="no-background"
  >
    <template #content>
      <ImageGalleryZoomed :key="activeIndex" :images="images" :current-index="activeIndex"></ImageGalleryZoomed>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useBreakpoints } from "@vueuse/core";
import { Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref, onMounted, computed, getCurrentInstance } from "vue";
import { useLanguages } from "@/core/composables";
import { BREAKPOINTS } from "@/core/constants";
import { extractNumberFromString } from "@/core/utilities";
import { transformImageUrls } from "@/shared/thorlabs/shared/image-resize";
import type { ImageTypeExtension } from "@/core/api/graphql/types";
import type SwiperCore from "swiper";
import ImageGalleryZoomed from "@/shared/catalog/components/image-gallery-zoomed.vue";
import Dialog from "@/shared/thorlabs/shared/components/dialog-modal.vue";

interface IProps {
  images?: ImageTypeExtension[];
}

const props = withDefaults(defineProps<IProps>(), {
  images: () => [],
});

const { currentLocale } = useLanguages();

const componentId = `image-gallery_${getCurrentInstance()!.uid}`;
const THUMBS_PER_VIEW = 5;
const DEFAULT_IMAGE_SIZE = computed(() => (props.images?.length > 1 ? 405 : 461));
const DEFAULT_THUMB_SIZE = 40;
const breakpoints = useBreakpoints(BREAKPOINTS);
const isDesktop = breakpoints.greaterOrEqual("md");
const mdScreenWidth = extractNumberFromString(BREAKPOINTS.md);
const activeIndex = ref(0);
const imagesSwiper = ref<SwiperCore | null>(null);
const thumbsSwiper = ref<SwiperCore | null>(null);
const showSwiperNav = ref(false);
const dialog = ref<InstanceType<typeof Dialog> | null>(null);
const swiperModules = [Navigation, Thumbs];

const setImagesSwiper = (swiper: SwiperCore) => {
  imagesSwiper.value = swiper;

  swiper.on("slideChange", function () {
    const newActiveIndex = swiper.activeIndex;
    const firstVisibleIndex = thumbsSwiper.value?.activeIndex;
    const lastVisibleIndex = (thumbsSwiper.value?.activeIndex ?? 0) + THUMBS_PER_VIEW - 1;

    if (newActiveIndex === lastVisibleIndex) {
      thumbsSwiper.value?.slideNext();
    } else if (newActiveIndex === firstVisibleIndex) {
      thumbsSwiper.value?.slidePrev();
    }
  });
};

const setThumbsSwiper = (swiper: SwiperCore) => {
  thumbsSwiper.value = swiper;
};

const regularImages = computed(() =>
  transformImageUrls(
    props.images as { url: string; name: string; description: string | undefined }[],
    DEFAULT_IMAGE_SIZE.value,
    DEFAULT_IMAGE_SIZE.value,
  ),
);
const regularImages2x = computed(() =>
  transformImageUrls(
    props.images as { url: string; name: string; description: string | undefined }[],
    DEFAULT_IMAGE_SIZE.value,
    DEFAULT_IMAGE_SIZE.value,
    2,
  ),
);
const regularImages3x = computed(() =>
  transformImageUrls(
    props.images as { url: string; name: string; description: string | undefined }[],
    DEFAULT_IMAGE_SIZE.value,
    DEFAULT_IMAGE_SIZE.value,
    3,
  ),
);
const regularImages4x = computed(() =>
  transformImageUrls(
    props.images as { url: string; name: string; description: string | undefined }[],
    DEFAULT_IMAGE_SIZE.value,
    DEFAULT_IMAGE_SIZE.value,
    4,
  ),
);
const thumbImages = computed(() =>
  transformImageUrls(
    props.images as { url: string; name: string; description: string | undefined }[],
    DEFAULT_THUMB_SIZE,
    DEFAULT_THUMB_SIZE,
  ),
);
const thumbImages2x = computed(() =>
  transformImageUrls(
    props.images as { url: string; name: string; description: string | undefined }[],
    DEFAULT_THUMB_SIZE,
    DEFAULT_THUMB_SIZE,
    2,
  ),
);
const thumbImages3x = computed(() =>
  transformImageUrls(
    props.images as { url: string; name: string; description: string | undefined }[],
    DEFAULT_THUMB_SIZE,
    DEFAULT_THUMB_SIZE,
    3,
  ),
);
const thumbImages4x = computed(() =>
  transformImageUrls(
    props.images as { url: string; name: string; description: string | undefined }[],
    DEFAULT_THUMB_SIZE,
    DEFAULT_THUMB_SIZE,
    4,
  ),
);

const setActiveIndex = () => {
  activeIndex.value = imagesSwiper.value?.realIndex ?? 0;
};

const showDialog = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  dialog.value?.openDialog();
};

const handleShowDialog = (event: KeyboardEvent) => {
  if (event.key === "Enter" || event.key === " ") {
    event.preventDefault();
    showDialog();
  }
};

const handleKeyDownThumb = (event: KeyboardEvent, index: number) => {
  if (event.key === "Enter" || event.key === " ") {
    imagesSwiper.value?.slideTo(index);
  }
};

function getCaptionByLanguageCode(inputString: string | undefined): string | null {
  // Parse the input string into an array of tuples
  if (!inputString) {
    return null;
  }

  const parsedArray: [string, string][] = JSON.parse(inputString);

  // Find the matching language code
  const languageCode = currentLocale.value;
  const match = parsedArray.find(([code]) => code.includes(languageCode));

  // If a match is found, return the corresponding caption, otherwise return null
  return match ? match[1] : null;
}

onMounted(() => {
  if (thumbImages.value.length > THUMBS_PER_VIEW) {
    showSwiperNav.value = true;
  }
});
</script>

<style scoped lang="scss">
.image-gallery {
  @apply select-none flex flex-wrap md:flex-row-reverse md:gap-[5px] gap-4;

  &__images-container {
    @apply relative aspect-square max-w-full md:max-w-[calc(100%-56px)] self-start min-w-full;

    &.has-thumbnails {
      @apply flex-1;
      @media (min-width: theme("screens.md")) {
        min-width: 0 !important;
      }
    }
  }

  &__button {
    @apply absolute left-auto right-1 bottom-1 flex items-center justify-center border-0 bg-transparent z-[2] gap-1 font-medium;

    svg {
      @apply stroke-white stroke-1 scale-x-[-1] border rounded-full border-[--color-neutral-a4] w-[30px] h-[30px] text-[--color-neutral-a1] bg-white p-[6px];
    }

    &:hover {
      svg {
        @apply border-[--color-neutral-a2];
      }
    }
  }

  &__images {
    @apply w-full h-full bg-[--color-additional-50] rounded duration-300 ease-linear;
  }

  &__img {
    @apply relative w-full h-full rounded object-center object-contain cursor-zoom-in text-[0];

    &--no-img {
      @apply cursor-default;
    }
  }

  &__img-caption {
    @apply max-w-[calc(100%-45px)] absolute left-1 bottom-1 px-3 py-[7px] text-[--color-neutral-a1] bg-[--color-white] border border-[--color-neutral-a4] rounded-2xl text-xs font-medium;
  }

  &__thumbs-container {
    @apply w-full md:w-[51px] flex justify-center md:justify-start items-center flex-row md:flex-col md:max-w-[56px] md:max-h-[400px] flex-wrap md:flex-nowrap gap-2 md:gap-0;
  }

  &__thumbs {
    @apply min-w-full md:min-h-[calc(100%-82px)];
  }

  &__thumb {
    @apply p-[4.5px] aspect-square cursor-pointer border border-[--color-neutral-a4] rounded-[4px];

    &:hover {
      @apply border-[--color-neutral-a3];
    }

    &.swiper-slide-thumb-active {
      @apply border-[--color-neutral-a1];
    }
  }

  &__thumb-img {
    @apply w-full h-auto object-center object-contain text-[0];
  }

  &__nav-buttons {
    @apply flex justify-between items-center mt-4 md:flex-col gap-1;

    .vc-nav-button {
      @apply w-5 h-5 shadow-none bg-transparent text-[--color-neutral-a1];

      :deep(svg) {
        @apply stroke-white stroke-2 w-full h-full;
      }

      &:disabled,
      &--disabled {
        @apply text-[--color-neutral-a4] static;
      }
    }
  }
}
</style>
