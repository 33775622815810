<template>
  <VcWidget class="product-info vc-widget--no-shadow border-0" size="lg">
    <div class="product-info__container">
      <div class="product-info__side">
        <ImageGallery :images="product.images"></ImageGallery>

        <component
          :is="ProductBlocks['product-image-buttons']"
          v-if="ProductBlocks['product-image-buttons']"
          :cad-pdf-url="cadPdfFile"
          :family-page-url="product.descriptions.find((d) => d.reviewType === 'FamilyPageUrl')?.content"
          :three-d-url="threeDFile"
          class="hidden md:flex"
        />
      </div>

      <div class="product-info__print">
        <VcImage :src="product.imgSrc" class="product-info__print-img" />
      </div>
    </div>
  </VcWidget>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ImageGallery } from "@/shared/catalog";
import ProductBlocks from "@/shared/catalog/components/product";
import type { PageContent } from "../types";
import type { Product } from "@/core/api/graphql/types";

interface IProps {
  product: Product;
  model: PageContent;
}

const props = defineProps<IProps>();

const cadPdfFile = computed(() => {
  if (props.product.assets.filter((d) => d.group?.toLocaleLowerCase().includes("cad pdf")).length === 1) {
    return props.product.assets.find((d) => d.group?.toLocaleLowerCase().includes("cad pdf"))?.url;
  }

  return undefined;
});

const threeDFile = computed(() => {
  if (props.product.assets.filter((d) => d.group?.toLocaleLowerCase().includes("edrawing")).length === 1) {
    return props.product.assets.find((d) => d.group?.toLocaleLowerCase().includes("edrawing"))?.url;
  }

  return undefined;
});
</script>

<style lang="scss">
.product-info {
  &__container {
    @apply flex flex-col lg:flex-row lg:gap-8 print:flex-row print:gap-4;
  }

  &__side {
    @apply flex-none w-[100%] print:hidden;
  }

  &__videos {
    @apply mt-8 lg:mt-3;
  }

  &__print {
    @apply hidden aspect-square w-40 flex-none print:block;
  }

  &__print-img {
    @apply w-full rounded border;
  }

  &__content {
    @apply mt-5 flex flex-col gap-6 lg:mt-0 lg:grow print:mt-5 print:grow;
  }
}
</style>
