<template>
  <form class="overflow-x-hidden p-5" @submit.prevent="save">
    <slot name="prepend" v-bind="slotsData" />

    <div :class="{ 'flex flex-col': withPersonalInfo }">
      <div v-if="withPersonalInfo" class="w-full">
        <div class="flex justify-between gap-5">
          <VcInput
            v-model="lastName"
            :message="errors.lastName"
            :error="!!errors.lastName"
            :disabled="disabled"
            :label="$t('common.labels.last_name')"
            class="mb-4 w-full"
            required
            :maxlength="64"
          />

          <VcInput
            v-model="firstName"
            :message="errors.firstName"
            :error="!!errors.firstName"
            :disabled="disabled"
            :label="$t('common.labels.first_name')"
            class="mb-4 w-full"
            required
            :maxlength="64"
          />
        </div>

        <VcInput
          v-if="withDescriptionField"
          v-model="description"
          :message="(errors as Record<string, string>).description"
          :error="!!(errors as Record<string, string>).description"
          :disabled="disabled"
          :label="$t('common.labels.description')"
          class="mb-4"
          :maxlength="128"
        />

        <VcSelect
          v-model="country"
          text-field="name"
          :message="errors.countryCode"
          :error="!!errors.countryCode"
          :disabled="disabled"
          :items="countries"
          :label="$t('common.labels.country')"
          :placeholder="$t('common.placeholders.select_country')"
          class="mb-4 w-full"
          required
          autocomplete
        />

        <VcInput
          v-model="line1"
          :message="errors.line1"
          :error="!!errors.line1"
          :disabled="disabled"
          :label="$t('common.labels.address_line1')"
          class="mb-4"
          required
          :maxlength="128"
        />

        <div class="mb-5 w-full">
          <div>
            <button type="button" class="faq-btn flex w-full text-left" @click="handleToggle">
              <div class="flex items-center gap-1 font-medium text-[--color-neutral-a1] underline">
                <VcIcon name="plus-alt-sm" :size="7"></VcIcon>
                Address Line 2 (Department, Building, Mailstop)
              </div>
            </button>

            <div v-show="formOpen" class="mt-5">
              <VcInput placeholder="Department" label="Department" class="w-full" />
              <VcInput placeholder="Building" label="Building" class="mt-5 w-full" />
              <VcInput placeholder="Mailstop" label="Mailstop" class="mt-5 w-full" />
            </div>
          </div>
        </div>

        <VcInput
          v-model="line2"
          :message="errors.line2"
          :error="!!errors.line2"
          :disabled="disabled"
          :label="$t('common.labels.address_line2')"
          class="mb-4"
          :maxlength="128"
        />

        <VcInput
          v-model="postalCode"
          :message="errors.postalCode"
          :error="!!errors.postalCode"
          :disabled="disabled"
          :label="$t('common.labels.zip_or_postal_code')"
          class="mb-4 w-full"
          required
          :maxlength="32"
        />

        <div class="flex gap-5">
          <VcInput
            v-model="city"
            :message="errors.city"
            :error="!!errors.city"
            :disabled="disabled"
            :label="$t('common.labels.city')"
            class="mb-4 w-full"
            :required="requiredCity"
            :maxlength="128"
          />

          <VcSelect
            v-model="region"
            text-field="name"
            :items="regions"
            :message="errors.regionId"
            :error="!!errors.regionId"
            :required="!!regions.length"
            :disabled="disabled || !regions.length"
            :label="$t('common.labels.region')"
            :placeholder="$t('common.placeholders.select_region')"
            class="mb-4 w-full"
            autocomplete
          />
        </div>

        <VcInput
          v-model="phone"
          :message="errors.phone"
          :error="!!errors.phone"
          :disabled="disabled"
          :required="requiredPhone"
          :label="$t('common.labels.phone')"
          class="mb-4"
          :maxlength="64"
        />

        <VcInput
          v-model="email"
          :message="errors.email"
          :error="!!errors.email"
          :disabled="disabled"
          :required="requiredEmail"
          :label="$t('common.labels.email')"
          class="mb-4"
          :maxlength="64"
        />

        <div class="flex flex-col gap-5 pb-5 text-base font-medium leading-6 text-[--color-neutral-a1]">
          <VcCheckbox>Use as Default Billing Address</VcCheckbox>
          <VcCheckbox>Use as Default Shipping Address</VcCheckbox>
        </div>

        <div class="mb-5 w-full">
          <div>
            <button type="button" class="faq-btn flex w-full text-left" @click="handleToggle">
              <div class="flex items-center gap-1 font-medium text-[--color-neutral-a1] underline">
                <VcIcon name="plus-alt-sm" :size="7"></VcIcon>
                Tax Exempt No. (Optional)
              </div>
            </button>

            <div v-show="formOpen">
              <VcInput placeholder="Tax Exempt No" class="w-full" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <slot name="append" v-bind="slotsData" />
  </form>
</template>

<script setup lang="ts">
import { toTypedSchema } from "@vee-validate/yup";
import { useField, useForm } from "vee-validate";
import { computed, watch, ref } from "vue";
import * as yup from "yup";
import { getAddressName, Logger } from "@/core/utilities";
import type { CountryRegionType, CountryType, MemberAddressType } from "@/core/api/graphql/types";

const emit = defineEmits<IEmits>();
const props = withDefaults(defineProps<IProps>(), {
  countries: () => [],
});
const open = ref(false);
const formOpen = ref(false);

const handleToggle = () => {
  formOpen.value = !formOpen.value;
  open.value = false;
};

interface IEmits {
  (event: "update:modelValue", address: MemberAddressType): void;
  (event: "save", address: MemberAddressType): void;
}

interface IProps {
  modelValue?: MemberAddressType;
  disabled?: boolean;
  requiredEmail?: boolean;
  requiredPhone?: boolean;
  requiredCity?: boolean;
  withDescriptionField?: boolean;
  withPersonalInfo?: boolean;
  countries?: CountryType[];
}

const initialValues: MemberAddressType = {
  isDefault: false,
  isFavorite: false,
  firstName: "",
  lastName: "",
  email: "",
  organization: "",
  postalCode: "",
  countryCode: "",
  countryName: "",
  regionId: "",
  regionName: "",
  city: "",
  line1: "",
  line2: "",
  phone: "",
  description: "",
};

const { values, meta, errors, handleSubmit, setErrors, validate, resetForm } = useForm<MemberAddressType>({
  initialValues,
});

const slotsData = computed(() => ({
  setErrors,
  validate,
  reset: resetForm,
  save,
  errors,
  values,
  dirty: meta.value.dirty,
  valid: meta.value.valid,
  validated: meta.value.validated,
  pending: meta.value.pending,
  touched: meta.value.touched,
}));

const emailRules = computed(() => {
  let rules = yup.string().trim().max(64).email().nullable();
  if (props.withPersonalInfo && props.requiredEmail) {
    rules = rules.required();
  }
  return toTypedSchema(rules);
});

const phoneRules = computed(() => {
  let rules = yup.string().trim().max(64).nullable();
  if (props.withPersonalInfo && props.requiredPhone) {
    rules = rules.required();
  }
  return toTypedSchema(rules);
});

const cityRules = computed(() => {
  let rules = yup.string().trim().max(128).nullable();
  if (props.requiredCity) {
    rules = rules.required();
  }
  return toTypedSchema(rules);
});

const regionRules = computed(() => {
  // Do not use computed based on field value cause it may cause infinite loop
  const rules = yup
    .string()
    .nullable()
    .when("countryCode", {
      is: (value: string) => props.countries.find((item) => value === item.id)?.regions.length,
      then: (schema) => schema.required(),
    });
  return toTypedSchema(rules);
});

const firstNameRules = computed(() => {
  let rules = yup.string().trim().max(64).nullable();
  if (props.withPersonalInfo) {
    rules = rules.required();
  }
  return toTypedSchema(rules);
});

const lastNameRules = computed(() => {
  let rules = yup.string().trim().max(64).nullable();
  if (props.withPersonalInfo) {
    rules = rules.required();
  }
  return toTypedSchema(rules);
});

const country = computed<CountryType | undefined>({
  get: () => props.countries.find((item) => countryCode.value === item.id),
  set: (value?: CountryType) => {
    countryCode.value = value?.id ?? "";
    countryName.value = value?.name ?? "";
    regionId.value = "";
    regionName.value = "";
  },
});

const regions = computed<CountryRegionType[]>(() => country.value?.regions ?? []);

const region = computed<CountryRegionType | undefined>({
  get: () => regions.value.find((item) => regionId.value === item.id),
  set: (value?: CountryRegionType) => {
    regionId.value = value?.id ?? "";
    regionName.value = value?.name ?? "";
  },
});

const { value: email } = useField("email", emailRules);
const { value: city } = useField("city", cityRules);
const { value: phone } = useField("phone", phoneRules);
const { value: firstName } = useField("firstName", firstNameRules);
const { value: lastName } = useField("lastName", lastNameRules);
const { value: postalCode } = useField("postalCode", toTypedSchema(yup.string().trim().max(32).required().nullable()));
const { value: countryCode } = useField("countryCode", toTypedSchema(yup.string().required().nullable()));
const { value: countryName } = useField("countryName", toTypedSchema(yup.string().max(128).nullable()));
const { value: regionName } = useField("regionName", toTypedSchema(yup.string().max(128).nullable()));
const { value: regionId } = useField("regionId", regionRules);
const { value: line1 } = useField("line1", toTypedSchema(yup.string().trim().max(128).required().nullable()));
const { value: line2 } = useField("line2", toTypedSchema(yup.string().trim().max(128).nullable()));
const { value: description } = useField("description", toTypedSchema(yup.string().trim().max(128).nullable()));

const save = handleSubmit((address) => {
  const newAddress: MemberAddressType = { ...address, name: getAddressName(address) };
  emit("update:modelValue", newAddress);
  emit("save", newAddress);
}, Logger.debug);

watch(
  () => props.modelValue,
  (modelValue) => {
    resetForm({ values: modelValue });
  },
  { deep: true, immediate: true },
);
</script>
