<template>
  <section
    aria-label="header"
    :class="[
      'relative w-full transition-[top]',
      { 'sticky z-40': isHeaderVisible && isScrolledPastHeader },
      isHeaderVisible ? 'top-0' : '-top-full',
    ]"
    role="banner"
  >
    <div class="header relative z-40 w-full border-b border-b-[--color-white] bg-[color:var(--color-header-bottom-bg)]">
      <!-- region Default slot -->
      <transition>
        <div v-if="customSlots.default">
          <component :is="customSlots.default" />
        </div>

        <div v-else class="relative z-50 flex h-16 w-full items-center justify-between gap-x-2 sm:gap-x-6">
          <!-- region Left slot -->
          <component :is="customSlots.left" v-if="customSlots.left" />

          <div v-else class="flex h-full items-center">
            <!-- trigger menu icon -->
            <div class="flex h-full items-center justify-center pe-3 ps-4">
              <button
                v-if="!mobileMenuVisible"
                ref="mobileMenuButton"
                type="button"
                class="flex size-10 items-center justify-center rounded border border-[color:var(--color-neutral-a2)]"
                aria-label="Open mobile menu"
                @click="handleOpen"
              >
                <VcIcon class="text-[--color-neutral-a2]" name="menu" :size="30" />
              </button>

              <button
                v-else
                ref="mobileMenuButton"
                type="button"
                class="flex size-10 items-center justify-center rounded border border-[color:var(--color-white)]"
                aria-label="Close mobile menu"
                @click="handleClose"
              >
                <VcIcon class="text-[--color-neutral-a2]" name="menu-close" :size="20" />
              </button>
            </div>
            <router-link to="/" class="max-w-40">
              <VcImage
                :src="logo?.url"
                :alt="$context.storeName"
                :width="150"
                :height="24"
                class="h-6 min-w-full"
                loading="eager"
                fallback-src="/static/thorlabs/logo.svg"
              />
            </router-link>
          </div>
          <!-- endregion Left slot -->

          <!-- region Right slot -->
          <component :is="customSlots.right" v-if="customSlots.right" />

          <div v-else class="flex h-full flex-row items-center pr-4">
            <PushMessages v-if="$cfg.push_messages_enabled && isAuthenticated" class="px-1 py-2 xs:px-2">
              <template #trigger="{ totalCount, unreadCount }">
                <div class="relative">
                  <transition :name="unreadCount ? 'shake' : ''" mode="out-in">
                    <VcIcon :key="totalCount" class="text-primary" name="bell" :size="28" />
                  </transition>

                  <transition mode="out-in" name="scale">
                    <VcBadge
                      v-if="unreadCount"
                      variant="outline"
                      size="sm"
                      class="absolute -right-2 -top-2 transition-transform"
                      rounded
                    >
                      {{ unreadCount }}
                    </VcBadge>
                  </transition>
                </div>
              </template>
            </PushMessages>

            <template v-if="isAuthenticated">
              <!-- Authenticated user account dropdown -->
              <button type="button" class="flex px-3" @click="toggleAccount">
                <VcIcon name="user-circle" class="size-6 text-[color:var(--color-primary-a1)]" />
              </button>
              <div
                v-show="accountOpen"
                class="divide-stroke absolute right-0 top-[calc(100%+75px)] w-[200px] divide-y overflow-hidden border border-b-[3px] border-[color:var(--color-neutral-a4)] border-b-[color:var(--color-primary-a1)] bg-white p-5"
              >
                <div class="pb-4">
                  <p class="text-dark break-words text-base font-semibold text-[color:var(--color-neutral-a2)]">
                    {{ $t("common.messages.hello") }}, {{ user.contact?.fullName }}!
                  </p>
                </div>
                <div>
                  <a
                    href="javascript:void(0)"
                    class="text-dark flex w-full items-center justify-between py-4 text-base font-medium hover:bg-gray-50"
                  >
                    <span class="flex items-center gap-2"> My Account </span>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="text-dark flex w-full items-center justify-between pb-4 text-base font-medium hover:bg-gray-50"
                  >
                    <span class="flex items-center gap-2"> My Orders </span>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="text-dark flex w-full items-center justify-between pb-4 text-base font-medium hover:bg-gray-50"
                  >
                    <span class="flex items-center gap-2"> My Quotes </span>
                  </a>
                </div>
                <div>
                  <button
                    type="button"
                    class="text-dark flex w-full items-center justify-between py-4 text-base font-medium hover:bg-gray-50"
                    @click="() => signMeOut()"
                  >
                    <span class="flex items-center gap-2"> {{ $t("shared.layout.header.link_logout") }} </span>
                  </button>
                </div>
              </div>
            </template>
            <template v-else>
              <button
                ref="accountOpenElement"
                type="button"
                class="relative flex px-3 py-6 after:absolute after:left-0 after:top-full after:block after:h-8 after:w-full after:content-normal"
                :class="{
                  'hover:[&_svg]:text-primary ': accountOpen,
                }"
                aria-label="Account"
                @click="toggleAccount"
              >
                <VcIcon
                  class="text-[--color-neutral-a1]"
                  :class="{ 'text-primary': accountOpen }"
                  name="user"
                  :size="20"
                />
              </button>
              <div
                v-show="accountOpen"
                ref="accountOpenElement"
                class="absolute right-0 top-[calc(100%+75px)] w-full transition-all"
              >
                <div class="border-b-[3px] border-b-[color:var(--color-primary-a1)] bg-white p-8">
                  <SignInForm />
                </div>
              </div>
            </template>

            <router-link :to="{ name: 'Cart' }" class="mini-cart p-2" aria-label="Cart">
              <span class="relative flex items-center">
                <VcIcon class="text-[--color-neutral-a1]" name="cart" :size="20" />

                <transition
                  mode="out-in"
                  enter-from-class="scale-0"
                  leave-to-class="scale-0"
                  enter-active-class="will-change-transform"
                  leave-active-class="will-change-transform"
                >
                  <VcBadge
                    v-if="cart?.itemsQuantity"
                    variant="solid"
                    size="sm"
                    class="absolute -right-2 -top-2 transition-transform"
                    rounded
                  >
                    {{ $n(cart.itemsQuantity, "decimal", { notation: "compact" }) }}
                  </VcBadge>
                </transition>
              </span>
            </router-link>
          </div>
          <!-- endregion Right slot -->
        </div>
      </transition>
      <!-- endregion Default slot -->

      <BannerAlertHeader
        v-if="bannerBlock && bannerBlock.position === 'Bottom'"
        :id="`bannerAlertHeader${bannerBlock.position}`"
        ref="bannerAlertHeaderRefBottom"
        :block="bannerBlock"
      />

      <!-- region Mobile Search Bar -->
      <div class="mobile-search-bar px-4 pb-4 pt-2">
        <form
          class="relative flex w-full rounded border border-[color:var(--color-neutral-a3)] bg-[color:var(--color-neutral-a6)]"
        >
          <input
            type="text"
            :placeholder="$t('shared.layout.search_bar.enter_keyword_placeholder')"
            class="h-12 w-full rounded border border-transparent bg-[color:var(--color-neutral-a6)] pl-4 pr-12 text-base font-medium text-[color:var(--color-neutral-a1)] outline-none placeholder:[color:var(--color-neutral-a1)] focus-visible:shadow-none"
            @keyup.enter="searchPhrase && $router.push(searchPageLink)"
          />
          <button
            type="button"
            class="absolute right-0 top-0 flex size-12 items-center justify-center"
            aria-label="$t('shared.layout.search_bar.enter_keyword_placeholder')"
          >
            <VcIcon name="search" class="size-4 text-[color:var(--color-neutral-a1)]" />
          </button>
        </form>
      </div>
      <!-- endregion Mobile Search Bar -->
    </div>
    <!-- Mobile menu -->
    <transition
      enter-from-class="-translate-y-full"
      leave-to-class="-translate-y-full"
      enter-active-class="will-change-transform"
      leave-active-class="will-change-transform"
    >
      <MobileMenu
        v-if="mobileMenuVisible"
        :mobile-menu-items="mobileMenuItems"
        class="transition-transform print:hidden"
        @close-menu="handleClose"
      />
    </transition>
  </section>
</template>

<script setup lang="ts">
import { onClickOutside, whenever } from "@vueuse/core";
import { ref, onMounted, computed, watchEffect, onBeforeUnmount, watch } from "vue";
import { useRouteQueryParam } from "@/core/composables";
import { QueryParamName } from "@/core/enums";
import { SignInForm, useSignMeOut } from "@/shared/account";
import { useUser } from "@/shared/account/composables/useUser";
import { useShortCart } from "@/shared/cart";
import { useNestedMobileHeader, useSearchBar } from "@/shared/layout";
import BannerAlertHeader from "./banner-alert-header.vue";
import MobileMenu from "./mobile-menu.vue";
import type { IBannerBlock, ILogo, IMobileMenuItem } from "@/shared/thorlabs/blocks";
import type { Ref } from "vue";
import type { RouteLocationRaw } from "vue-router";
import PushMessages from "@/shared/push-messages/components/push-messages.vue";

defineProps<IProps>();
const searchPhrase = ref("");
const searchPhraseInUrl = useRouteQueryParam<string>(QueryParamName.SearchPhrase);
const mobileMenuVisible = ref(false);

const { isAuthenticated, user } = useUser();
const { customSlots } = useNestedMobileHeader();
const { searchBarVisible } = useSearchBar();
const { cart } = useShortCart();
const { signMeOut } = useSignMeOut();

interface IProps {
  logo?: ILogo;
  mobileMenuItems?: IMobileMenuItem[];
  bannerBlock?: IBannerBlock;
}

const accountOpen = ref(false);
const accountOpenElement = ref(null);
const mobileMenuButton = ref<HTMLElement | null>(null);

onClickOutside(accountOpenElement, () => {
  accountOpen.value = false;
});
const toggleAccount = () => {
  accountOpen.value = !accountOpen.value;
  if (accountOpen.value) {
    mobileMenuVisible.value = false;
  }
};

const handleOpen = () => {
  mobileMenuVisible.value = true;
};
const handleClose = () => {
  mobileMenuVisible.value = false;
};

const searchPageLink = computed<RouteLocationRaw>(() => ({
  name: "Search",
  query: {
    [QueryParamName.SearchPhrase]: searchPhrase.value,
  },
}));

watchEffect(() => (searchPhrase.value = searchPhraseInUrl.value ?? ""));
whenever(searchBarVisible, () => (searchPhrase.value = searchPhraseInUrl.value ?? ""), { immediate: true });
const isHeaderVisible = ref(true);

const watchAndSetOverflow = (refToWatch: Ref<boolean>) => {
  watch(refToWatch, (newVal) => {
    document.body.style.overflow = newVal ? "hidden" : "auto";
    const mainElement = document.getElementById("maincontent");
    if (newVal) {
      if (mainElement) {
        mainElement.classList.add("mobile-menu-open");
      }
    } else {
      if (mainElement) {
        mainElement.classList.remove("mobile-menu-open");
      }
    }
  });
};
// Use the function for both refs
watchAndSetOverflow(mobileMenuVisible);
watchAndSetOverflow(accountOpen);

const isScrollingUp = ref(false);
let lastScrollPosition = 0;
const isScrolledPastHeader = ref(false);
onMounted(() => {
  const handleScroll = () => {
    const currentScrollPosition = window.scrollY || document.documentElement.scrollTop;
    const headerElement = document.querySelector('[aria-label="header"]');
    const headerHeight = (headerElement as HTMLElement)?.offsetHeight || 0;
    isScrollingUp.value = currentScrollPosition < lastScrollPosition;
    isScrolledPastHeader.value = currentScrollPosition > headerHeight;

    isHeaderVisible.value = isScrollingUp.value || currentScrollPosition < headerHeight;

    lastScrollPosition = currentScrollPosition;
  };

  window.addEventListener("scroll", handleScroll, false);

  onBeforeUnmount(() => {
    window.removeEventListener("scroll", handleScroll, false);
  });
});
</script>

<style scoped lang="scss">
.alert-banner + .mobile-search-bar {
  @apply mt-2;
}
.header {
  transition: top 0.3s ease-in-out; // keep same animation as the tabNavBar
}
</style>
