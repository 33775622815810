<template>
  <div class="tl-table tl-table--striped product-variations border-0">
    <table>
      <thead>
        <tr>
          <th>{{ $t("shared.catalog.product_details.product_variations.serial_number") }}</th>
          <th class="min-w-60">{{ $t("shared.catalog.product_details.product_variations.description") }}</th>
          <th>{{ $t("shared.catalog.product_details.product_variations.data_sheet") }}</th>
          <th>{{ $t("shared.catalog.product_details.product_variations.availability") }}</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="variation in [product, ...product.variations]" :key="variation.code">
          <td>{{ variation.code as string }}</td>
          <td
            v-dompurify-html="
              variation.descriptions?.find((d) => d.reviewType?.toLocaleLowerCase() === 'title')?.content
            "
          ></td>
          <td class="!text-center">
            <a
              v-if="variation.dataSheetUrlTL"
              :href="variation.dataSheetUrlTL"
              :download="
                isPDF(variation.dataSheetUrlTL as string) ? false : getFileName(variation.dataSheetUrlTL as string)
              "
              target="_blank"
              rel="noopener noreferrer"
              class="product-variations__icon"
              @click.prevent="handleLinkClick(variation.dataSheetUrlTL as string)"
            >
              <VcIcon name="document-text" size="md" aria-hidden="true" />
            </a>
          </td>
          <!-- TODO: Availability -->
          <td class="!text-center">Today</td>
          <td>
            <AddToCart :product="variation" has-variations hide-quantity />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- <VcLineItems :with-header="false" class="variations-default">
    <template #line-items>
      <VcLineItem
        v-for="variation in [product, ...product.variations]"
        :key="variation.code"
        with-image
        with-price
        with-properties
        :image-url="variation.images[0]?.url"
        :name="variation.code as string"
        :properties="getProperties(variation as VariationTypeExtension)"
        :list-price="variation.price.list"
        :actual-price="variation.price.actual"
        :vendor="$cfg.vendor_enabled ? product.vendor : undefined"
      >
        <AddToCart :product="variation" has-variations hide-quantity />

        <div v-if="variation.dataSheetUrlTL">
          <a
            :href="variation.dataSheetUrlTL"
            :download="
              isPDF(variation.dataSheetUrlTL as string) ? false : getFileName(variation.dataSheetUrlTL as string)
            "
            target="_blank"
            rel="noopener noreferrer"
            @click.prevent="handleLinkClick(variation.dataSheetUrlTL as string)"
          >
            datasheet download icon
          </a>
        </div>

        {{ variation.name }}
      </VcLineItem>
    </template>
  </VcLineItems> -->
</template>

<script setup lang="ts">
import { AddToCart } from "@/shared/cart";
import type { Product } from "@/core/api/graphql/types";

interface IProps {
  product: Product;
}

defineProps<IProps>();

function isPDF(datasheetUrl: string) {
  return datasheetUrl.toLowerCase().endsWith(".pdf");
}

const getFileName = (url: string): string => {
  // Extract the file name from the URL
  const urlParts = url.split("/");
  return urlParts[urlParts.length - 1];
};

// Method to handle link click
const handleLinkClick = (datasheetUrl: string) => {
  // If the file is a PDF, let it open in a new tab
  if (isPDF(datasheetUrl)) {
    window.open(datasheetUrl, "_blank");
  } else {
    // Otherwise, trigger download by creating an anchor element programmatically
    const link = document.createElement("a");
    link.href = datasheetUrl;
    link.download = getFileName(datasheetUrl);
    link.click();
  }
};
</script>

<style lang="scss" scoped>
.product-variations {
  &__icon {
    :deep(svg) {
      --vc-icon-color: var(--color-primary-a1);
    }
  }
}
</style>
