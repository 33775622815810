<template>
  <form @submit="onSubmit">
    <!-- Errors block -->
    <VcAlert
      v-for="error in translatedErrors"
      :key="error.code"
      class="mb-4"
      color="danger"
      size="sm"
      variant="outline-dark"
      icon
    >
      <span v-if="error?.code === IdentityErrors.USER_IS_LOCKED_OUT">
        {{ error.translation }}
        <ContactAdministratorLink />.
      </span>

      <span v-else-if="error?.code === IdentityErrors.PASSWORD_EXPIRED" class="flex place-items-center justify-between">
        <span>
          {{ error.translation }}
        </span>
        <!-- Keep the A tag to reinitialize the app -->
        <a href="/change-password" class="text-sm font-semibold text-blue-700 hover:text-blue-500">
          {{ $t("common.buttons.set_new_password") }}
        </a>
      </span>

      <span v-else>
        {{ error.translation }}
      </span>
    </VcAlert>

    <VcInput
      v-model.trim="email"
      name="email"
      class="mb-4"
      :label="$t('common.labels.email_address')"
      :placeholder="$t('common.labels.email_address')"
      :disabled="loading"
      :message="validationErrors.email"
      :error="!!validationErrors.email"
      autocomplete="email"
    />

    <VcInput
      v-model="password"
      :label="$t('common.labels.password')"
      :placeholder="$t('common.placeholders.password')"
      :disabled="loading"
      type="password"
      :message="validationErrors.password"
      :error="!!validationErrors.password"
      autocomplete="password"
    />

    <VcButton
      color="primary"
      size="base"
      variant="solid"
      type="submit"
      class="mt-6 w-full rounded-md p-2 text-base font-semibold"
      :loading="loading"
    >
      <span>{{ $t("shared.account.sign_in_form.login_button") }}</span>
    </VcButton>

    <div class="mt-8 text-center text-[--body-text-color]">
      <div class="mb-4">
        <router-link to="/forgot-password" class="text-base text-[--link-color] hover:text-[--link-hover-color]">
          {{ $t("common.buttons.reset_password") }}
        </router-link>
        {{ $t("common.labels.or") }}
        <router-link
          v-t="'shared.account.sign_in_form.forgot_id'"
          to="/forgot-id"
          class="text-base text-[--link-color] hover:text-[--link-hover-color]"
          >{{ $t("shared.account.sign_in_form.forgot_id") }}</router-link
        >
      </div>
      {{ $t("pages.sign_in.no_account_yet") }}
      <router-link to="/sign-up" class="text-base text-[--link-color] hover:text-[--link-hover-color]">
        {{ $t("common.buttons.register") }}
      </router-link>
    </div>
  </form>
</template>

<script setup lang="ts">
import { toTypedSchema } from "@vee-validate/yup";
import { useField, useForm } from "vee-validate";
import { ref, toRef, watch } from "vue";
import { object, string } from "yup";
import { useErrorsTranslator } from "@/core/composables";
import { IdentityErrors } from "@/core/enums";
import { useSignMeIn } from "@/shared/account/composables";
import { ContactAdministratorLink } from "@/shared/common";

const schema = toTypedSchema(
  object({
    email: string().required(),
    password: string().required(),
  }),
);

const {
  errors: validationErrors,
  meta,
  handleSubmit,
} = useForm({
  validationSchema: schema,
});

const { value: email } = useField<string>("email");
const { value: password } = useField<string>("password");

const rememberMe = ref(false);

const model = toRef({ email, password, rememberMe });

const { errors, loading, signIn, resetErrors } = useSignMeIn(model);

const { translatedErrors } = useErrorsTranslator("shared.account.sign_in_form.errors", errors);

const onSubmit = handleSubmit(async () => {
  await signIn();
});

watch(meta, (value) => {
  if (value.touched) {
    resetErrors();
  }
});
</script>
