import type { IContent, IContentAreaItem, IImage } from "@/shared/thorlabs/shared";

export interface IHtmlBlock extends IContent {
  htmlContent: string;
}

export interface ITabContainerBlock extends IContent {
  content: IContentAreaItem<IContent>[];
}

export interface IResponsiveImageBlock extends IContent {
  desktopImage: IImage;
  tabletImage: IImage;
  mobileImage: IImage;
}

export interface IFooterCurrencyPage {
  id: number;
  workId: number;
  guidValue: string;
  url: string;
  providerName: string | null;
  href: string;
  text: string;
  target: string;
}

export interface ISocialMediaLink {
  icon: string;
  link: string;
}

export interface IAdditionalLink {
  displayName: string;
  link: string;
}

export interface IFooterCurrencyData {
  salesPhone: string;
  salesEmail: string;
  technicalPhone: string;
  technicalEmail: string;
  hoursLine1: string;
  hoursLine2: string;
  pages: IFooterCurrencyPage[];
  linkedPages: IFooterCurrencyPage[];
  socialMediaLinks: ISocialMediaLink[];
  additionalLink: IAdditionalLink;
}

export interface IFooterBlock {
  name: string;
  eurData: IFooterCurrencyData;
  usdData: IFooterCurrencyData;
  cnyData: IFooterCurrencyData;
  gbpData: IFooterCurrencyData;
  sekData: IFooterCurrencyData;
  jpyData: IFooterCurrencyData;
  brlData: IFooterCurrencyData;
  chinaDomainIdentifier: IAdditionalLink;
}

export interface ILogo {
  url: string;
}

export interface IVisualNavigationLayer {
  routeSegment: string;
}

export interface IVisualNavigationLayerPage {
  expanded: {
    name: string;
    visNavTitle: string;
    title: string;
    type: string;
    color: string;
    routeSegment: string;
    rows: IContentLink[];
  };
}

export interface IMenuImage {
  url: string | null;
}

export interface IStandardPage {
  name: string;
  type: string;
  routeSegment: string | null;
  contentLink?: IContentLink; // Optional
  menuIcon?: IMenuImage; // Optional
}

export type ExpandedTypes =
  | IMultiLevelMenuItemBlock
  | IMenuItemBlock
  | IVisualNavigationLayerPage
  | IStandardPage
  | IHtmlBlock
  | { name: string };

export interface IContentLink {
  expanded:
  | IMultiLevelMenuItemBlock
  | IMenuItemBlock
  | IVisualNavigationLayerPage
  | IStandardPage
  | IHtmlBlock
  | { name: string };
  url?: string | null; // Optional
}

export interface IMenuItemBlock {
  visualNavigationLayerPage: IVisualNavigationLayerPage;
  name: string;
  contentType: string[];
  items: { contentLink: IContentLink }[];
  menuImage?: IMenuImage; // Optional
  menuIcon?: IMenuImage; // Optional
  rightColumnItems: { contentLink: IContentLink }[];
}

export interface IMultiLevelMenuItemBlock {
  name: string;
  contentType: string[];
  visualNavigationLayer: { expanded: IVisualNavigationLayer };
  items: { contentLink: IContentLink }[];
  secondaryItems: { contentLink: IContentLink }[];
}

export interface IDesktopMenuItem {
  displayOption: string;
  contentLink: IContentLink;
}

export interface IMobileMenuItem {
  displayOption: string;
  tag: string | null;
  contentLink: IContentLink;
}

export interface IHeaderBlock {
  name: string;
  logo: ILogo;
  desktopMenuItems: IDesktopMenuItem[];
  mobileMenuItems: IMobileMenuItem[];
  banner: IContentAreaItem<IContent>[];
}

export interface IBannerBlock extends IContent {
  name: string;
  color: string;
  position: string;
  currency: string;
  text: string;
  saved: string;
  icon: {
    url: string | undefined;
  };
}

export interface IMenuItem {
  label: string;
  url: string;
  icon: string;
  menuTemplate: string;
  items: Array<IMenuSubItem>;
  rightColumnItems: Array<IMenuSubItem>;
  active?: boolean;
}

export interface IMenuSubItem {
  label: string;
  url: string;
  icon: string;
  image: string;
  items: Array<IMenuSubItem>;
  color: string;
  separator: boolean;
  active?: boolean;
}

export function mapGraphQLDataToMenuItems(
  receivedMenuItems: IDesktopMenuItem[] | IMobileMenuItem[] | undefined,
  isMobile: boolean = false,
): IMenuItem[] {
  const menuItems: IMenuItem[] = [];

  // Iterate over the GraphQL data to extract and map properties
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  receivedMenuItems?.forEach((item: IDesktopMenuItem | IMobileMenuItem | any) => {
    const expandedItem = item.contentLink.expanded;
    const menuTemplate = getMenuTemplate(expandedItem);
    const menuItem: IMenuItem = {
      label: expandedItem.visualNavigationLayerPage?.expanded?.title
        ? item.visualNavigationLayerPage?.expanded?.title
        : expandedItem.name,
      url: getUrlFromGraphQLItem(expandedItem),
      icon: expandedItem.menuIcon?.url || "",
      menuTemplate: menuTemplate,
      items: mapSubItems(expandedItem, menuTemplate, isMobile),
      rightColumnItems: menuTemplate === "two-columns" ? mapRightColumnItems(expandedItem, menuTemplate, isMobile) : [],
    };

    menuItems.push(menuItem);
  });

  processMenuItemsUrls(menuItems);
  return menuItems;
}

function getUrlFromGraphQLItem(item: ExpandedTypes): string {
  if (item && "visualNavigationLayer" in item && item.visualNavigationLayer?.expanded?.routeSegment) {
    return item.visualNavigationLayer.expanded.routeSegment;
  } else if (item && "visualNavigationLayerPage" in item && item.visualNavigationLayerPage?.expanded?.routeSegment) {
    return item.visualNavigationLayerPage.expanded.routeSegment;
  } else if (item && "routeSegment" in item) {
    return item.routeSegment || "";
  } else {
    return "";
  }
}

function getMenuTemplate(item: ExpandedTypes): string {
  if ("contentType" in item) {
    if (item.contentType[1] === "MultiLevelMenuItemBlock") {
      const menuItemBlock = item as IMultiLevelMenuItemBlock;
      return menuItemBlock.items?.length > 0 || menuItemBlock.secondaryItems?.length > 0 ? "three-columns" : "";
    } else if (item.contentType[1] === "MenuItemBlock") {
      const menuItemBlock = item as IMenuItemBlock;
      const hasItems = menuItemBlock.items?.some(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (child: any) =>
          child.contentLink?.expanded?.items?.length > 0 || child.contentLink?.expanded?.rightColumnItems?.length > 0,
      );
      return hasItems ? "two-columns" : "one-column";
    }
  }
  return "";
}

function mapSubItems(
  expandedItem: IMenuItemBlock | IMultiLevelMenuItemBlock,
  menuTemplate: string,
  isMobile: boolean,
): IMenuSubItem[] {
  const mappedSubItems: IMenuSubItem[] = [];
  // Check if items exist and map them
  if (expandedItem && "items" in expandedItem && expandedItem.items) {
    mappedSubItems.push(
      ...mapItems(
        expandedItem.items.map((item) => ({ contentLink: item.contentLink })),
        menuTemplate,
        isMobile,
      ),
    );
  }

  // Check if rightColumnItems exist and map them
  if (
    expandedItem &&
    "rightColumnItems" in expandedItem &&
    expandedItem.rightColumnItems &&
    expandedItem.rightColumnItems.length > 0 &&
    (menuTemplate != "two-columns" || isMobile)
  ) {
    const rightColumnItems: IMenuSubItem[] = mapItems(
      expandedItem.rightColumnItems.map((item) => ({ contentLink: item.contentLink })),
      menuTemplate,
      isMobile,
    );
    mappedSubItems.push(...rightColumnItems);
  }

  // Check if secondaryItems exist and map them
  if (
    expandedItem &&
    "secondaryItems" in expandedItem &&
    expandedItem.secondaryItems &&
    expandedItem.secondaryItems.length > 0
  ) {
    const secondaryItems: IMenuSubItem[] = mapItems(
      expandedItem.secondaryItems.map((item) => ({ contentLink: item.contentLink })),
      menuTemplate,
      isMobile,
      true,
    );

    mappedSubItems.push(...secondaryItems);
  }

  return mappedSubItems;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mapRightColumnItems(expandedItem: any, menuTemplate: string, isMobile: boolean): IMenuSubItem[] {
  const mappedSubItems: IMenuSubItem[] = [];

  if (expandedItem.rightColumnItems && expandedItem.rightColumnItems.length > 0) {
    const rightColumnItems: IMenuSubItem[] = mapItems(expandedItem.rightColumnItems, menuTemplate, isMobile);

    mappedSubItems.push(...rightColumnItems);
  }

  return mappedSubItems;
}

function mapItems(
  menuItems: { contentLink: IContentLink }[],
  menuTemplate: string,
  isMobile: boolean,
  isRightColumn: boolean = false,
): IMenuSubItem[] {
  const getImageUrl = (expandedItem: ExpandedTypes): string =>
    expandedItem && "menuImage" in expandedItem ? (expandedItem as IMenuItemBlock).menuImage?.url || "" : "";

  const getIconUrl = (expandedItem: ExpandedTypes): string =>
    expandedItem && "menuIcon" in expandedItem
      ? (expandedItem as IMenuItemBlock | IStandardPage).menuIcon?.url || ""
      : "";

  const getLabel = (expandedItem: ExpandedTypes): string =>
    expandedItem && "name" in expandedItem ? expandedItem.name : "";

  const getSubItems = (expandedItem: ExpandedTypes): IMenuSubItem[] =>
    expandedItem && "items" in expandedItem && expandedItem.items
      ? mapSubItems(expandedItem as IMenuItemBlock | IMultiLevelMenuItemBlock, menuTemplate, isMobile)
      : [];

  const getColor = (expandedItem: ExpandedTypes): string =>
    expandedItem &&
      "visualNavigationLayerPage" in expandedItem &&
      expandedItem.visualNavigationLayerPage?.expanded?.color
      ? expandedItem.visualNavigationLayerPage.expanded.color
      : "";

  return menuItems.map((subItem) => {
    const expandedItem = subItem.contentLink.expanded;
    const imageUrl = getImageUrl(expandedItem);
    const iconUrl = getIconUrl(expandedItem);
    const label = getLabel(expandedItem);
    const items = menuTemplate === "three-columns" && isMobile ? [] : getSubItems(expandedItem);
    const color = getColor(expandedItem);
    const separator =
      isRightColumn && (menuItems.indexOf(subItem) === 0 || menuItems.indexOf(subItem) === menuItems.length - 1);

    return {
      label: label,
      url: getUrlFromGraphQLItem(expandedItem),
      icon: iconUrl,
      image: imageUrl,
      items: items,
      color: color,
      separator: separator,
    };
  });
}

export function processMenuItemsUrls(menuItems: IMenuItem[]): void {
  menuItems.forEach((menuItem) => {
    if (menuItem.menuTemplate === "two-columns") {
      menuItem.items.forEach((item) => {
        if (item.items.length === 0) {
          item.url = item.url === "" ? "/" : item.url;
        }
      });
    }
  });
}
