<template>
  <div class="flex flex-wrap gap-x-[10px] gap-y-1 font-medium leading-none">
    <div v-if="showUserDiscount" class="text-[--color-neutral-a2] line-through">
      {{ listWithTax?.formattedAmount }}
    </div>

    <div class="flex flex-wrap gap-x-2 gap-y-1">
      <VcPriceDisplay
        v-if="shouldUseActualPrice(value?.list, value?.actual)"
        class="font-bold"
        :class="priceColorClass"
        :value="useCnyExtraTaxPrice ? actualWithTax : value?.actual"
      />
      <VcPriceDisplay v-else class="font-bold" :value="useCnyExtraTaxPrice ? listWithTax : value?.list" />

      <div v-if="showUserDiscount" class="ml-[-2px]">({{ userDiscountPercentage }}% Discount)</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useCurrency } from "@/core/composables";
import { shouldUseActualPrice } from "@/ui-kit/utilities/price";
import type { MoneyType, PriceType } from "@/core/api/graphql/types";

interface IProps {
  value?: PriceType | { list: MoneyType; actual: MoneyType };
  priceColorClass?: string;
  userDiscountPercent?: number | undefined;
  listWithTax?: MoneyType;
  actualWithTax?: MoneyType;
}

const props = withDefaults(defineProps<IProps>(), {
  priceColorClass: "",
});
const { currentCurrency } = useCurrency();

const useCnyExtraTaxPrice = computed(
  () =>
    currentCurrency.value.code === "CNY" &&
    !!props.listWithTax &&
    !!props.value &&
    props.listWithTax.amount > props.value.actual.amount,
);
const showUserDiscount = computed(() => props.userDiscountPercent && props.userDiscountPercent > 0);
const userDiscountPercentage = computed(() =>
  props.userDiscountPercent ? props.userDiscountPercent * 100 : undefined,
);
</script>
