'use strict';
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { parseKeyToGuid } from "../helpers";
import { ApiClient } from "./apiClient";
import { defaultConfig } from "./apiClientConfig";
import { ApiConstant } from "./apiConstant";
/**
 * Class for load Form
 */
export class FormLoader {
    /**
     *  Constructor an instance of FormLoader
     *
     * @param config Optional config to use. This config will combined with the defaultConfig.
     */
    constructor(config) {
        this.client = new ApiClient(Object.assign(Object.assign({}, defaultConfig), config));
    }
    /**
     * Get a form with a GUID
     *
     * @param key GUID of form
     * @param language Localization for form
     * @returns A promise with a form
     */
    getForm(key, language) {
        return new Promise((resolve, reject) => {
            this.client.get(`${ApiConstant.apiEndpoint}/${key}`, { locale: language })
                .then((response) => {
                resolve(response);
            })
                .catch((error) => {
                reject(error);
            });
        });
    }
    /**
     * Query a form with a GUID
     *
     * @param optiGraphUrl The endpoint url of Optimizely Graph
     * @param key GUID of form
     * @param language Localization for form
     * @returns A promise with a form
     */
    queryForm(optiGraphUrl, key, language) {
        return new Promise((resolve, reject) => {
            let query = `
            query FormQuery($key: String, $language: String) {
                FormContainerBlock (
                    where: {
                        ContentLink: {
                            GuidValue:{eq: $key}
                        }
                        Language: {
                            Name: {eq: $language}
                        }
                    }
                ){
                    items {
                        FormRenderTemplate
                    }
                }
            }
            `;
            let variables = { key: parseKeyToGuid(key), language };
            fetch(optiGraphUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    query,
                    variables,
                }),
            })
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                if (response.ok) {
                    let json = yield response.json();
                    let formStr = (_a = json.data.FormContainerBlock.items[0]) === null || _a === void 0 ? void 0 : _a.FormRenderTemplate;
                    if (formStr) {
                        resolve(JSON.parse(formStr));
                    }
                    else {
                        reject(response);
                    }
                }
                else {
                    reject(response);
                }
            }))
                .catch((error) => {
                reject(error);
            });
        });
    }
}
