<template>
  <div class="language-selector flex items-stretch gap-x-1.5">
    <VcDropdownMenu
      placement="top-start"
      width="100%"
      max-height="h-auto"
      :y-offset="-1"
      class="relative w-full"
      :class="{ 'open-content': open }"
      @toggle="toggle($event)"
    >
      <template #trigger>
        <div
          class="flex h-10 w-full flex-wrap content-between items-center rounded-md border border-[color:var(--color-neutral-a3)] bg-white py-[10px] pl-[15px] pr-[10px] text-sm leading-[1.3] text-[color:var(--color-footer-top-text)]"
          :class="{ 'rounded-t-none': open }"
        >
          <span class="flex flex-1 items-center gap-2">
            <span class="flex shrink-0 items-center rounded-full text-[color:var(--color-neutral-a2)]">
              <VcIcon class="shrink-0 text-[--color-footer-top-text]" :size="12" name="world" />
            </span>
            <span class="line-clamp-1 flex-1 text-left font-bold">
              {{ $t(`shared.layout.footer.${currentLanguage.twoLetterLanguageName}`) }}
              <!-- {{ currentLanguage.nativeName }} -->
            </span>
            <VcIcon
              class="shrink-0 stroke-[white] stroke-2 text-[--color-footer-top-text]"
              size="xs"
              :name="open ? 'chevron-up' : 'chevron-down'"
            />
          </span>
        </div>
      </template>

      <template #content>
        <VcMenuItem
          v-for="item in supportedLanguages"
          :key="item.twoLetterLanguageName"
          :active="item.twoLetterLanguageName === currentLanguage?.twoLetterLanguageName"
          color="neutral"
          @click="
            item.twoLetterLanguageName === currentLanguage?.twoLetterLanguageName
              ? null
              : select(item.twoLetterLanguageName)
          "
        >
          <span class=""> {{ $t(`shared.layout.footer.${item.twoLetterLanguageName}`) }}</span>
        </VcMenuItem>
      </template>
    </VcDropdownMenu>
  </div>
</template>

<script setup lang="ts">
import { ref, shallowRef } from "vue";
import { updateContact } from "@/core/api/graphql";
import { useLanguages } from "@/core/composables";
import { useUser } from "@/shared/account/composables/useUser";
import type { InputUpdateContactTypeExtension } from "@/core/api/graphql/types";

const { currentLanguage, supportedLanguages, saveLocale } = useLanguages();
const { user } = useUser();
const open = ref(false);
const listElement = shallowRef<HTMLElement | null>(null);

function hideList() {
  const HIDE_TIMEOUT = 350;
  open.value = false;

  setTimeout(() => {
    if (listElement.value) {
      listElement.value.scrollTop = 0;
    }
  }, HIDE_TIMEOUT);
}

function toggle(value: boolean) {
  open.value = value;
}

async function select(locale: string) {
  if (user.value?.contact?.id) {
    const contact: InputUpdateContactTypeExtension = {
      defaultLanguage: supportedLanguages.value.find((lang) => lang.twoLetterLanguageName === locale)?.cultureName,
      currencyCode: user.value?.contact.currencyCode,
      firstName: user.value?.contact.firstName,
      lastName: user.value?.contact.lastName,
      id: user.value?.contact.id,
    };

    await updateContact(contact);
  }

  saveLocale(locale);
  hideList();
}
</script>

<style lang="scss">
.language-selector .vc-menu-item {
  &__inner {
    @apply p-[10px];

    &.vc-menu-item__inner--size--md {
      @apply py-[9px] px-[15px];
    }
  }
}
.open-content {
  .vc-dropdown-menu__list {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
footer [id^="popover-"],
footer [id*=" popover-"] {
  inset: auto 0px 0px auto !important;
  transform: translate3d(-0.5px, -39px, 0px) !important;
}
</style>
