import { useLocalStorage } from "@vueuse/core";
import { computed } from "vue";
import { setLocale as setLocaleForYup } from "yup";
import { useUser } from "@/shared/account/composables/useUser";
import { useFetch } from "../api/common/composables/useFetch";
import { countryCodeToLanguageAndCurrencyMap, languagesToOverwrite } from "../constants/language-and-currency";
import FeatureFlags from "../utilities/featureFlags";
import { useThemeContext } from "./useThemeContext";
import type { ILanguage } from "../types";
import type { LanguageAndCurrency } from "../types/defaultLanguageCurrency";
import type { I18n } from "@/i18n";
import type { Composer } from "vue-i18n";

const { themeContext } = useThemeContext();
const { user } = useUser();

const savedLocale = useLocalStorage<string>("locale", "");

const defaultLanguage = computed<ILanguage>(() => themeContext.value.defaultLanguage);
const defaultLocale = computed<string>(() => defaultLanguage.value.twoLetterLanguageName);
const supportedLanguages = computed<ILanguage[]>(() => {
  const availableLanguages = themeContext.value.availableLanguages;
  const orderedLanguages = ["en", "zh", "ja"];
  return availableLanguages.sort((a, b) => {
    const indexA = orderedLanguages.indexOf(a.twoLetterLanguageName);
    const indexB = orderedLanguages.indexOf(b.twoLetterLanguageName);

    return indexA - indexB;
  });
});
const supportedLocales = computed<string[]>(() => supportedLanguages.value.map((item) => item.twoLetterLanguageName));
const contactLocale = computed(() => user.value?.contact?.defaultLanguage?.split("-")[0]);
const countryCodeTLDLanguage = computed(() => {
  if (themeContext.value.storeSettings.modules) {
    const featureFlags = new FeatureFlags(themeContext.value.storeSettings.modules);
    const ccTLDsValue = (featureFlags.getValue("Optimizely.Connector", "ccTLDs") as string) || "";
    const domains = ccTLDsValue ? (JSON.parse(ccTLDsValue) as Record<string, LanguageAndCurrency>) : {};

    return domains[location.hostname]?.Language;
  }

  return null;
});
const currentLocale = computed<string>(() => {
  const localeInPath = location.pathname.split("/")[1];
  let locale: string = defaultLocale.value;

  if (supportedLocales.value.includes(localeInPath)) {
    locale = localeInPath;
  } else if (contactLocale.value) {
    locale = contactLocale.value;
  } else if (supportedLocales.value.includes(savedLocale.value)) {
    locale = savedLocale.value;
  } else if (countryCodeTLDLanguage.value) {
    locale = countryCodeTLDLanguage.value;
  } else {
    locale = getGeolocationLocale();
  }

  return locale;
});

const currentLanguage = computed<ILanguage>(
  () =>
    supportedLanguages.value.find((language) => language.twoLetterLanguageName === currentLocale.value) ||
    defaultLanguage.value,
);

async function fetchLocaleMessages(locale: string, optimizelyHost: string): Promise<Record<string, unknown>> {
  try {
    const sections = [
      "shared",
      "common",
      "pages",
      "file_error",
      "identity_error",
      "validation_error",
      "password_tips",
      "ui_kit",
    ];
    const params = new URLSearchParams();
    sections.forEach((section) => params.append("sections", section));
    const { data } = await useFetch<JSON>(`${optimizelyHost}/api/resources/${locale}?${params.toString()}`).json();
    if (data.value) {
      return data.value;
    } else {
      return import(`../../../locales/en.json`).then((m) => m.default);
    }
  } catch (error) {
    // Fallback to English or any static import if the fetch fails
    return import("../../../locales/en.json").then((m) => m.default);
  }
}

async function setLocale(i18n: I18n, locale: string, optimizelyHost: string): Promise<void> {
  let messages = i18n.global.getLocaleMessage(locale);

  if (!Object.keys(messages).length) {
    messages = await fetchLocaleMessages(locale, optimizelyHost);
    i18n.global.setLocaleMessage(locale, messages);
  }

  (i18n.global as unknown as Composer).locale.value = locale;

  savedLocale.value = locale;

  setLocaleForYup({
    mixed: {
      required: i18n.global.t("common.messages.required_field"),
    },
    string: {
      email: i18n.global.t("common.messages.email_is_not_correct"),
      max: ({ max }) => i18n.global.t("common.messages.max_length", { max }),
    },
  });

  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the "fetch" API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */

  document.documentElement.setAttribute("lang", locale);
}

function saveLocale(locale: string, needToReload: boolean = true) {
  const { pathname } = location;
  const splitPathname = pathname.split("/");
  const localeInPath = splitPathname[1];
  const indexOfRemainingPath = 2;
  const path = supportedLocales.value?.includes(localeInPath)
    ? `/${splitPathname.slice(indexOfRemainingPath).join("/")}`
    : pathname;

  savedLocale.value = locale;

  if (needToReload) {
    location.href = locale === defaultLocale.value ? path : `/${locale}${path}`;
  }
}

function getGeolocationLocale(): string {
  const countryCodeValue =
    countryCodeToLanguageAndCurrencyMap[
      themeContext.value.countryCode as keyof typeof countryCodeToLanguageAndCurrencyMap
    ];

  if (countryCodeValue) {
    if (languagesToOverwrite.includes(countryCodeValue.Language)) {
      return defaultLanguage.value.twoLetterLanguageName;
    } else {
      return (
        supportedLanguages.value.find((language) => language.twoLetterLanguageName === countryCodeValue.Language)
          ?.twoLetterLanguageName || defaultLanguage.value.twoLetterLanguageName
      );
    }
  }

  return defaultLanguage.value.twoLetterLanguageName;
}

export function useLanguages() {
  return {
    savedLocale,
    defaultLanguage,
    defaultLocale,
    supportedLanguages,
    supportedLocales,
    currentLocale,
    currentLanguage,
    setLocale,
    saveLocale,
    fetchLocaleMessages,
  };
}
