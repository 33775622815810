import type { LanguageAndCurrency } from "../types/defaultLanguageCurrency";

export const countryCodeToLanguageAndCurrencyMap: Record<string, LanguageAndCurrency> = {
  BR: { Language: "pt", Currency: "BRL" },
  CN: { Language: "zh", Currency: "CNY" },
  HK: { Language: "zh", Currency: "CNY" },
  MO: { Language: "zh", Currency: "CNY" },
  TW: { Language: "zh", Currency: "CNY" },
  AL: { Language: "en", Currency: "EUR" },
  AD: { Language: "en", Currency: "EUR" },
  AM: { Language: "en", Currency: "EUR" },
  AZ: { Language: "en", Currency: "EUR" },
  BY: { Language: "en", Currency: "EUR" },
  BE: { Language: "en", Currency: "EUR" },
  BA: { Language: "en", Currency: "EUR" },
  BG: { Language: "en", Currency: "EUR" },
  HR: { Language: "en", Currency: "EUR" },
  CY: { Language: "en", Currency: "EUR" },
  CZ: { Language: "en", Currency: "EUR" },
  DK: { Language: "en", Currency: "EUR" },
  EE: { Language: "en", Currency: "EUR" },
  FI: { Language: "en", Currency: "EUR" },
  GE: { Language: "en", Currency: "EUR" },
  GR: { Language: "en", Currency: "EUR" },
  HU: { Language: "en", Currency: "EUR" },
  IS: { Language: "en", Currency: "EUR" },
  IE: { Language: "en", Currency: "EUR" },
  IT: { Language: "en", Currency: "EUR" },
  KZ: { Language: "en", Currency: "EUR" },
  XK: { Language: "en", Currency: "EUR" },
  LV: { Language: "en", Currency: "EUR" },
  LI: { Language: "en", Currency: "EUR" },
  LT: { Language: "en", Currency: "EUR" },
  LU: { Language: "en", Currency: "EUR" },
  MK: { Language: "en", Currency: "EUR" },
  MT: { Language: "en", Currency: "EUR" },
  MD: { Language: "en", Currency: "EUR" },
  MC: { Language: "en", Currency: "EUR" },
  ME: { Language: "en", Currency: "EUR" },
  NL: { Language: "en", Currency: "EUR" },
  NO: { Language: "en", Currency: "EUR" },
  PL: { Language: "en", Currency: "EUR" },
  RO: { Language: "en", Currency: "EUR" },
  RU: { Language: "en", Currency: "EUR" },
  SM: { Language: "en", Currency: "EUR" },
  RS: { Language: "en", Currency: "EUR" },
  SK: { Language: "en", Currency: "EUR" },
  SI: { Language: "en", Currency: "EUR" },
  ES: { Language: "en", Currency: "EUR" },
  CH: { Language: "en", Currency: "EUR" },
  TR: { Language: "en", Currency: "EUR" },
  UA: { Language: "en", Currency: "EUR" },
  VA: { Language: "en", Currency: "EUR" },
  FR: { Language: "fr", Currency: "EUR" },
  AT: { Language: "de", Currency: "EUR" },
  DE: { Language: "de", Currency: "EUR" },
  PT: { Language: "pt", Currency: "EUR" },
  GB: { Language: "en", Currency: "GBP" },
  MY: { Language: "en", Currency: "JPY" },
  PH: { Language: "en", Currency: "JPY" },
  SG: { Language: "en", Currency: "JPY" },
  TH: { Language: "en", Currency: "JPY" },
  VN: { Language: "en", Currency: "JPY" },
  JP: { Language: "ja", Currency: "JPY" },
  SE: { Language: "sv", Currency: "SEK" },
};

export const languagesToOverwrite = ["es", "pt", "fr", "sv"];
