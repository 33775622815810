<template>
  <div class="action-buttons my-8 flex flex-wrap items-center justify-center gap-4 whitespace-nowrap md:mb-0">
    <VcButton v-if="cadPdfUrl" color="secondary" variant="outlineTL" size="md" @click="goToUrl(cadPdfUrl || '')">
      <VcIcon name="icon-cad" :size="16" class="self-center text-[--color-neutral-a05]" />
      <span class="product-image-buttons__text">CAD PDF</span>
    </VcButton>
    <VcButton v-if="threeDUrl" color="secondary" variant="outlineTL" size="md" @click="goToUrl(threeDUrl || '')">
      <VcIcon name="icon-box" :size="16" class="self-center text-[--color-neutral-a05]" />
      <span class="product-image-buttons__text">3D</span>
    </VcButton>
    <VcButton
      v-if="familyPageUrl"
      color="secondary"
      variant="outlineTL"
      size="md"
      @click="goToUrl(familyPageUrl || '')"
    >
      <VcIcon name="icon-arrow-up" :size="16" class="self-center text-[--color-neutral-a05]" />
      <span class="product-image-buttons__text">Product Family</span>
    </VcButton>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";

interface Props {
  cadPdfUrl?: string;
  threeDUrl?: string;
  familyPageUrl?: string;
}

defineProps<Props>();

const goToUrl = (url: string) => {
  window.open(url, "_blank");
};
</script>

<style lang="scss" scoped>
.action-buttons {
  @media (max-width: 374px) {
    @apply gap-3;
    .vc-button--outlineTL--secondary {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }
}
</style>
